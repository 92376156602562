"use strict";

var allMatchType = "Все матчи";

document.addEventListener("DOMContentLoaded", function (event) {
	var burger = document.querySelector('.header__burger');
	var menu = document.querySelector('.header__mobile-group');
	var descriptor = document.querySelector('.logo__descriptor--header');
	var wrapper = document.querySelector('.wrapper');

	var body = document.body;
	var lastScroll = 10;

	// Меню и бургер
	if (burger) {
		burger.addEventListener('click', function (e) {
			burger.classList.toggle('header__burger--active');
			menu.classList.toggle('header__mobile-group--active');
			//descriptor.classList.toggle('logo__descriptor--active');
			body.classList.toggle('lock');
		});

		// Расписание

		if (document.getElementsByClassName('schedule__rows').length !== 0) {
			getMatches();
		}
	}

	// Поведение шапки по скроллу
	window.addEventListener('scroll', function () {
		var currentScroll = window.pageYOffset;

		if (currentScroll <= 0) {
			body.classList.remove('scroll-up');
		}

		if (currentScroll > lastScroll && !body.classList.contains('scroll-down')) {
			body.classList.remove('scroll-up');
			body.classList.add('scroll-down');
		}

		if (currentScroll < lastScroll && body.classList.contains('scroll-down')) {
			body.classList.remove('scroll-down');
			body.classList.add('scroll-up');
		}

		lastScroll = currentScroll;
	});

	// Слайдеры
	new Swiper('.slider', {
		parallax: true,
		observer: true,
		observeParents: true,
		observeChildren: true,
		navigation: {
			nextEl: '.slider__button-next',
			prevEl: '.slider__button-prev'
		},
		autoHeight: true,
		slideToClickedSlide: true,
		watchOverflow: true,
		preloadImages: false,
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		lazy: {
			loadOnTransitionStart: true,
			loadPrevNext: true
		},
		watchSlidesProgress: true,
		watchSlidesVisibility: true,
		thumbs: {
			swiper: {
				el: '.mini-slider',
				slidesPerView: 1.5,
				breakpoints: {
					501: {
						slidesPerView: 3
					}
				}
			}
		}
	});

	new Swiper('.players-slider', {
		slidesPerView: 1.5,
		breakpoints: {
			501: {
				slidesPerView: 2.5
			},
			768: {
				slidesPerView: 3
			},
			1024: {
				slidesPerView: 5
			}
		},
		parallax: true,
		observer: true,
		observeParents: true,
		observeChildren: true,
		navigation: {
			nextEl: '.players__button-next',
			prevEl: '.players__button-prev'
		},
		autoHeight: true,
		slideToClickedSlide: true,
		watchOverflow: true,
		preloadImages: false,
		lazy: {
			loadOnTransitionStart: true,
			loadPrevNext: true
		},
		watchSlidesProgress: true,
		watchSlidesVisibility: true
	});
});

// В расписании редакчим чё кого
if (document.getElementById('schedule__select') !== null) {
	document.getElementById('schedule__select').addEventListener('change', function (e) {
		allMatchType = e.target.value;

		//console.log(document.getElementsByClassName('schedule__row'));
		//console.log(e.target.value);
		getMatches();
	});
};

function getMatches() {
	Array.from(document.getElementsByClassName("schedule__row")).forEach(function (item) {

		var matchFinish = item.dataset.matchfinish;
		var matchType = item.dataset.matchtype;

		if (allMatchType !== matchType) {
			item.style.display = 'none';
		} else {
			item.style.display = 'flex';
		};

		if (allMatchType == "Все матчи") {
			item.style.display = 'flex';
		}

		if (matchFinish == "Нет") {
			item.lastElementChild.lastElementChild.classList.remove("row__score--final");
		} else {
			item.lastElementChild.firstElementChild.style.display = "flex";
		}
	});
}